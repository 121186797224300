<!--
// nuxt-ui/components/Shared/SearchField.vue
-->
<script setup lang="ts">
type Props = {
  marginAuto?: boolean;
};
defineProps<Props>();

const model = defineModel<string | null>({ default: null });

const clearValue = (): void => {
  model.value = null;
};

const updateValue = (e): void => {
  model.value = e.target.value;
};
</script>

<template>
  <span
    class="flex py-2 px-4 items-center bg-gray-900 rounded-lg w-full"
    :class="{ 'md:ml-auto': marginAuto }"
  >
    <span class="search-icon">
      <svg
        class="w-4 h-4 text-gray-600"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 20 20"
      >
        <path
          d="M8 15.5a7.5 7.5 0 1 0 0-15 7.5 7.5 0 0 0 0 15Zm11.707 2.793-4-4a1 1 0 0 0-1.414 1.414l4 4a1 1 0 0 0 1.414-1.414Z"
        />
      </svg>
    </span>
    <input
      type="text"
      :value="model"
      class="search-input"
      :placeholder="$t('shared.search.placeholder')"
      @blur="e => updateValue(e)"
      @keydown.enter="e => updateValue(e)"
    />
    <span v-if="!!modelValue" class="close-icon cursor-pointer ml-auto" @click="clearValue">
      <svg
        class="w-2.5 h-2.5 text-gray-400"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 14 14"
      >
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
        />
      </svg>
    </span>
  </span>
</template>

<style scoped>
.search-input {
  @apply appearance-none text-gray-400 text-xs md:text-sm bg-gray-900 border-0 focus:ring-0 focus-visible:ring-0 md:py-0 py-0.5 px-2 w-full;
}
</style>
